export const photos = [
    {
      src: "./tableaux/Inde 2 46x38.JPG",
      alt:"Inde 2 46x38 Philippe Carvin",
    width: 2.6,
    height: 3.2,
    title: "Inde 2 46x38",
    caption: "Inde 2 46x38",
    loading: 'lazy'
    },
    {
      src: "./tableaux/La pause 41x33.JPG",
      alt:"La pause 41x33 Philippe Carvin",
    width: 1.7,
    height: 2,
    title: "La pause 41x33",
    caption: "La pause 41x33",
    loading: 'lazy'
    },
    {
      src: "./tableaux/Inde 3 46x38.JPG",
      alt:"Inde 3 46x38 Philippe Carvin",
      width: 1.6,
      height: 1.9,
      title: "Inde 3 46x38",
      caption: "Inde 3 46x38",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Clown trompette 50x65.JPG",
      alt:"Clown trompette 50x65 Philippe Carvin",
      width: 1.7,
      height: 2.3,
      title: "Clown trompette 50x65",
      caption: "Clown trompette 50x65",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Perrot 3 33x24.JPG",
      alt:"Perrot 3 33x24 Philippe Carvin",
      width: 3,
      height: 3.7,
      title: "Perrot 3 33x24",
      caption: "Perrot 3 33x24",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Pierrot 73x60.JPG",
      alt:"Pierrot 73x60 Philippe Carvin",
      width: 1.2,
      height: 1.5,
      title: "Pierrot 73x60",
      caption: "Pierrot 73x60",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Marionette 100x81.JPG",
      alt:"Marionette 100x81 Philippe Carvin",
      width: 2,
      height: 2.5,
      title: "Marionette 100x81",
      caption: "Marionette 100x81",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Coup de trombone 41x33.JPG",
      alt:"Coup de trombone 41x33 Philippe Carvin",
      width: 1.16,
      height: 1.4,
      title: "Coup de trombone 41x33",
      caption: "Coup de trombone 41x33",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Clown 2 50x65.JPG",
      alt:"Clown 2 50x65 Philippe Carvin",
      width: 2.5,
      height: 3.4,
      title: "Clown 2 50x65",
      caption: "Clown 2 50x65",
      loading: 'lazy'
    }
    ,
    {
      src: "./tableaux/Danseuse 41x33.JPG",
      alt:"Danseuse 41x33 Philippe Carvin",
      width: 2.7,
      height: 3.6,
      title: "Danseuse 41x33",
      caption: "Danseuse 41x33",
      loading: 'lazy'
    }
    ,
    {
      src: "./tableaux/Clown 5 41x27.JPG",
      alt:"Clown 5 41x27 Philippe Carvin",
      width: 2.7,
      height: 3.6,
      title: "Clown 5 41x27",
      caption: "Clown 5 41x27",
      loading: 'lazy'
    }
    ,
    {
      src: "./tableaux/Violoniste 73x60.JPG",
      alt:"Violoniste 73x60 Philippe Carvin",
      width: 1.6,
      height: 1.9,
      title: "Violoniste 73x60",
      caption: "Violoniste 73x60",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Venisienne 73x60.JPG",
      alt:"Venisienne 73x60 Philippe Carvin",
      width: 2.6,
      height: 3.2,
      title: "Venisienne 73x60",
      caption: "Venisienne 73x60",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Richard 55x46.JPG",
      alt:"Richard 55x46 Philippe Carvin",
      width: 1.6,
      height: 1.9,
      title: "Richard 55x46",
      caption: "Richard 55x46",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Eleonor 55x46.JPG",
      alt:"Eleonor 55x46 Philippe Carvin",
      width: 3,
      height: 4,
      title: "Eleonor 55x46",
      caption: "Eleonor 55x46",
      loading: 'lazy'
    }
    ,
    {
      src: "./tableaux/Vielle au puit 41x33.JPG",
      alt:"Vielle au puit 41x33 Philippe Carvin",
      width: 1.6,
      height: 2,
      title: "Vielle au puit 41x33",
      caption: "Vielle au puit 41x33",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Perrot 46x38.JPG",
      alt:"Perrot 46x38 Philippe Carvin",
      width: 3,
      height: 3.7,
      title: "Perrot 46x38",
      caption: "Perrot 46x38",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Pose 4 24x19.JPG",
      alt:"Pose 4 24x19 Philippe Carvin",
      width: 2.3,
      height: 3.1,
      title: "Pose 4 24x19",
      caption: "Pose 4 24x19",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Pose 2 21x29.JPG",
      alt:"Pose 2 21x29 Philippe Carvin",
      width: 3,
      height: 4.5,
      title: "Pose 2 21x29",
      caption: "Pose 2 21x29",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Pose 5 21x29.JPG",
      alt:"Pose 5 21x29 Philippe Carvin",
      width: 1.8,
      height: 2.4,
      title: "Pose 5 21x29",
      caption: "Pose 5 21x29",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Pose 1 21x29.JPG",
      alt:"Pose 1 21x29 Philippe Carvin",
      width: 1.6,
      height: 2.5,
      title: "Pose 1 21x29",
      caption: "Pose 1 21x29",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Clown 21x29.JPG",
      alt:"Clown 21x29 Philippe Carvin",
      width: 2.2,
      height: 3,
      title: "Clown 21x29",
      caption: "Clown 21x29",
      loading: 'lazy'
    }
    ,
    {
      src: "./tableaux/Venise cheveaux de bronze 92x73.JPG",
      alt:"Venise cheveaux de bronze 92x73 Philippe Carvin",
      width: 3.1,
      height: 4,
      title: "Venise cheveaux de bronze 92x73",
      caption: "Venise cheveaux de bronze 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Tigre 33x41.JPG",
      alt:"Tigre 33x41 Philippe Carvin",
      width: 1.7,
      height: 2.2,
      title: "Tigre 33x41",
      caption: "Tigre 33x41",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Blanca 41x33.JPG",
      alt:"Blanca 41x33 Philippe Carvin",
      width: 2.7,
      height: 3.7,
      title: "Blanca 41x33",
      caption: "Blanca 41x33",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Retour de chasse 61x50.JPG",
      alt:"Retour de chasse 61x50 Philippe Carvin",
      width: 3,
      height: 3.6,
      title: "Retour de chasse 61x50",
      caption: "Retour de chasse 61x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Retour de peche 55x46.JPG",
      alt:"Retour de peche 55x46 Philippe Carvin",
      width: 2.1,
      height: 2.5,
      title: "Retour de peche 55x46",
      caption: "Retour de peche 55x46",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Phare 1 46x38.JPG",
      alt:"Phare 1 46x38 Philippe Carvin",
      width: 2.2,
      height: 2.7,
      title: "Phare 1 46x38",
      caption: "Phare 1 46x38",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Orage 61x50.JPG",
      alt:"Orage 61x50 Philippe Carvin",
      width: 3,
      height: 4,
      title: "Orage 61x50",
      caption: "Orage 61x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Dernier voyage 558x46.JPG",
      alt:"Dernier voyage 558x46 Philippe Carvin",
      width: 2.7,
      height: 2.3,
      title: "Dernier voyage 558x46",
      caption: "Dernier voyage 558x46",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Echouée 33x41.JPG",
      alt:"Echouée 33x41 Philippe Carvin",
      width: 1.9,
      height: 1.5,
      title: "Echouée 33x41",
      caption: "Echouée 33x41",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Loire 2 41x33.JPG",
      alt:"Loire 2 41x33 Philippe Carvin",
      width: 2.3,
      height: 1.8,
      title: "Loire 2 41x33",
      caption: "Loire 2 41x33",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Marenne 92x73.JPG",
      alt:"Marenne 92x73 Philippe Carvin",
      width: 2.7,
      height: 3.7,
      title: "Marenne 92x73",
      caption: "Marenne 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Attente de la Marée 35x27.JPG",
      alt:"Attente de la Marée 35x27 Philippe Carvin",
      width: 1.6,
      height: 2.1,
      title: "Attente de la Marée 35x27",
      caption: "Attente de la Marée 35x27",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Port aiguillon 38x55.JPG",
      alt:"Port aiguillon 38x55 Philippe Carvin",
      width: 3.5,
      height: 2.4,
      title: "Port aiguillon 38x55",
      caption: "Port aiguillon 38x55",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Sur calles 61x50.JPG",
      alt:"Sur calles 61x50 Philippe Carvin",
      width: 2.6,
      height: 3.7,
      title: "Sur calles 61x50",
      caption: "Sur calles 61x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/La voile rouge 81x65.JPG",
      alt:"La voile rouge 81x65 Philippe Carvin",
      width: 2.9,
      height: 3.6,
      title: "La voile rouge 81x65",
      caption: "La voile rouge 81x65",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Barque échoué 33x24.JPG",
      alt:"Barque échoué 33x24 Philippe Carvin",
      width: 2.8,
      height: 3.4,
      title: "Barque échouée 33x24",
      caption: "Barque échouée 33x24",
      loading: 'lazy'
    },
    {
      src: "./tableaux/L'Indre 3 92x73.JPG",
      alt:"L'Indre 3 92x73 Philippe Carvin",
      width: 3,
      height: 3.7,
      title: "L'Indre 3 92x73",
      caption: "L'Indre 3 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/L'indre 2 65x54.JPG",
      alt:"L'indre 2 65x54 Philippe Carvin",
      width: 2.5,
      height: 3.1,
      title: "L'indre 2 65x54",
      caption: "L'indre 2 65x54",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Petit pont Azay le rideau 92x73.JPG",
      alt:"Petit pont Azay le rideau 92x73 Philippe Carvin",
      width: 2.3,
      height: 3,
      title: "Petit pont Azay le rideau 92x73",
      caption: "Petit pont Azay le rideau 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Reflet sur l'Indre 33x24.JPG",
      alt:"Reflet sur l'Indre 33x24 Philippe Carvin",
      width: 2.6,
      height: 3.2,
      title: "Reflet sur l'Indre 33x24",
      caption: "Reflet sur l'Indre 33x24",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Fleuraison 65x24.JPG",
      alt:"Fleuraison 65x24 Philippe Carvin",
      width: 1.4,
      height: 1.7,
      title: "Fleuraison 65x24",
      caption: "Fleuraison 65x24",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Reflet automne 146x114.JPG",
      alt:"Reflet automne 146x114 Philippe Carvin",
      width: 1.2,
      height: 1.8,
      title: "Reflet automne 146x114",
      caption: "Reflet automne 146x114",
      loading: 'lazy'
    },
    {
      src: "./tableaux/L'octroi 65x54.JPG",
      alt:"L'octroi 65x54 Philippe Carvin",
      width: 2.8,
      height: 3.4,
      title: "L'octroi 65x54",
      caption: "L'octroi 65x54",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Moulin Azay le rideau 92x73.JPG",
      alt:"Moulin Azay le rideau 92x73 Philippe Carvin",
      width: 2.7,
      height: 2.2,
      title: "Moulin Azay le rideau 92x73",
      caption: "Moulin Azay le rideau 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/La lagune 92x73.JPG",
      alt:"La lagune 92x73 Philippe Carvin",
      width: 2.6,
      height: 3.2,
      title: "La lagune 92x73",
      caption: "La lagune 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Bibliothèque 73x60.JPG",
      alt:"Bibliothèque 73x60 Philippe Carvin",
      width: 1.6,
      height: 2.5,
      title: "Bibliothèque 73x60",
      caption: "Bibliothèque 73x60",
      loading: 'lazy'
    },
    {
      src: "./tableaux/L'indre Azay le rideau 3 73x60.JPG",
      alt:"L'indre Azay le rideau 3 73x60 Philippe Carvin",
      width: 2.5,
      height: 3.3,
      title: "L'indre Azay le rideau 3 73x60",
      caption: "L'indre Azay le rideau 3 73x60",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Cave Rochecorbon 55x46.JPG",
      alt:"Cave Rochecorbon 55x46 Philippe Carvin",
      width: 1.85,
      height: 1.85,
      title: "Cave Rochecorbon 55x46",
      caption: "Cave Rochecorbon 55x46",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Départ Inde 69x50.JPG",
      alt:"Départ Inde 69x50 Philippe Carvin",
      width: 2.7,
      height: 1.9,
      title: "Départ Inde 69x50",
      caption: "Départ Inde 69x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Golf de Ballan 2 44x55.JPG",
      alt:"Golf de Ballan 2 44x55 Philippe Carvin",
      width: 2.6,
      height: 3.6,
      title: "Golf de Ballan 2 44x55",
      caption: "Golf de Ballan 2 44x55",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Roses 35x27.JPG",
      alt:"Roses 35x27 Philippe Carvin",
      width: 1,
      height: 1.2,
      title: "Roses 35x27",
      caption: "Roses 35x27",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Japonisantes 73x60.JPG",
      alt:"Japonisantes 73x60 Philippe Carvin",
      width: 2.8,
      height: 3.3,
      title: "Japonisantes 73x60",
      caption: "Japonisantes 73x60",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Botanique 92x73.JPG",
      alt:"Botanique 92x73 Philippe Carvin",
      width: 2.7,
      height: 3.9,
      title: "Botanique 92x73",
      caption: "Botanique 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Arbres-thème.JPG",
      alt:"Arbres-thème Philippe Carvin",
      width: 1.6,
      height: 1.1,
      title: "Arbres-thème",
      caption: "Arbres-thème",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Boulot 92x73.JPG",
      alt:"Boulot 92x73 Philippe Carvin",
      width: 1.4,
      height: 1.7,
      title: "Boulot 92x73",
      caption: "Boulot 92x73",
      loading: 'lazy'
    },  
    {
      src: "./tableaux/Matinèe d'automne 61x50.JPG",
      alt:"Matinèe d'automne 61x50 Philippe Carvin",
      width: 1.6,
      height: 1.4,
      title: "Matinèe d'automne 61x50",
      caption: "Matinèe d'automne 61x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Allée des grands arbres 92x73.JPG",
      alt:"Allée des grands arbres 92x73 Philippe Carvin",
      width: 1.5,
      height: 1.8,
      title: "Allée des grands arbres 92x73",
      caption: "Allée des grands arbres 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Arbres 11 92x73.JPG",
      alt:"Arbres 11 92x73 Philippe Carvin",
      width: 1.4,
      height: 1.8,
      title: "Arbres 11 92x73",
      caption: "Arbres 11 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Arbres 8 92x73.JPG",
      alt:"Arbres 8 92x73 Philippe Carvin",
      width: 1.9,
      height: 1.5,
      title: "Arbres 8 92x73",
      caption: "Arbres 8 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Petit pont 61x50.JPG",
      alt:"Petit pont 61x50 Philippe Carvin",
      width: 2.3,
      height: 2.8,
      title: "Petit pont 61x50",
      caption: "Petit pont 61x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Arbres 9 92x73.JPG",
      alt:"Arbres 9 92x73 Philippe Carvin",
      width: 2.1,
      height: 1.6,
      title: "Arbres 9 92x73",
      caption: "Arbres 9 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Arbres 10 92x73.JPG",
      alt:"Arbres 10 92x73 Philippe Carvin",
      width: 1.7,
      height: 1.4,
      title: "Arbres 10 92x73",
      caption: "Arbres 10 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Nuit lumineuse 35x27.JPG",
      alt:"Nuit lumineuse 35x27 Philippe Carvin",
      width: 2.1,
      height: 2.5,
      title: "Nuit lumineuse 35x27",
      caption: "Nuit lumineuse 35x27",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Automnal 92x73.JPG",
      alt:"Automnal 92x73 Philippe Carvin",
      width: 1.8,
      height: 1.4,
      title: "Automnal 92x73",
      caption: "Automnal 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Le bout du chemin 61x50.JPG",
      alt:"Le bout du chemin 61x50 Philippe Carvin",
      width: 1.3,
      height: 1.6,
      title: "Le bout du chemin 61x50",
      caption: "Le bout du chemin 61x50",
      loading: 'lazy'
    },
    {
    src: "./tableaux/Arbres 7 61x50.JPG",
      alt:"Arbres 7 61x50 Philippe Carvin",
      width: 1.5,
      height: 1.8,
      title: "Arbres 7 61x50",
      caption: "Arbres 7 61x50",
      loading: 'lazy'
    },
    {
      src: "./tableaux/Arbres 5 65x54.JPG",
      alt:"Arbres 5 65x54 Philippe Carvin",
      width: 1.7,
      height: 1.4,
      title: "Arbres 5 65x54",
      caption: "Arbres 5 65x54",
      loading: 'lazy'
    },
    {
      src: "./tableaux/La ville au loin 92x763.JPG",
      alt:"La ville au loin 92x763 Philippe Carvin",
      width: 3.1,
      height: 4.3,
      title: "La ville au loin 92x763",
      caption: "La ville au loin 92x763",
      loading: 'lazy'
    },
    {
    src: "./tableaux/Arbres 6 92x73.JPG",
      alt:"Arbres 6 92x73 Philippe Carvin",
      width: 2,
      height: 1.6,
      title: "Arbres 6 92x73",
      caption: "Arbres 6 92x73",
      loading: 'lazy'
    },
  ];
  