import "./Header.css"
import sign from './sign.png';
import photoID from './photoID.jpg';
import React, { useState, useEffect } from 'react';

const Header = () => {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 970px)").matches
      )
    
    useEffect(() => {
        window
        .matchMedia("(min-width: 970px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <>
        {matches && (
            <>
            <div className="myHeader">
                <img className="logoImage" src={sign} alt="logo"></img>
                <div className="bioContainer">
                    <p>MA PEINTURE</p>
                    <p>Figuratif, dessins ou peintures rehaussées de couleur et de lumières tous les thèmes me passionne dans une expression de plaisir et de libertés,</p>
                    <p>Réaliser un pont mystérieux entre l'âme du tableaux et des personnages incluant le spectateur,</p>
                    <p>Élève de Jean Abadie et de René Brassart que je ne remercierai jamais assez.</p>
                    <h1>Philippe Carvin</h1>
                </div>
                <img className="photoID" src={photoID} alt="logo"></img>
            </div>
            <hr/>
            </>
        )}
        {!matches && (
            <>
            <div className="myHeader">
                <img className="logoImage" src={sign} alt="logo"></img>
                
                <img className="photoID" src={photoID} alt="logo"></img>

                
            </div>
            <div className="bioContainer">
                <p>MA PEINTURE</p>
                <p>Figuratif, dessins ou peintures rehaussées de couleur et de lumières tous les thèmes me passionne dans une expression de plaisir et de libertés,</p>
                <p>Réaliser un pont mystérieux entre l'âme du tableaux et des personnages incluant le spectateur,</p>
                <p>Élève de Jean Abadie et de René Brassart que je ne remercierai jamais assez.</p>
                <h1>Philippe Carvin</h1>
            </div>
        <hr/>
        </>
        )}
        </>
    );
}

export default Header