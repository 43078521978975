import "./Footer.css"

const Footer = () => {
    return (
        <>
        <p className="copyright">Copyright Carvin@2023</p>
        <a className="contactButton" href="mailto:philippecarvin@gmail.com?body=Bonjour">Me contacter</a>
        </>
    )
}

export default Footer